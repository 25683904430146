.liquidation-wrapper {
  background: url("../../images/markets/market-bg.png") no-repeat center;
  background-size: cover;
  padding-top: 150px;
  display: flex;
  justify-content: center;
  flex: 1;
  text-align: center;
  color: white;
  font-size: 20px;
}
