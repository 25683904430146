/* @import "~antd/dist/antd.css"; */
/* @import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap");
@import "~antd/dist/antd.css";

:root {
  --white: #fff;
  --black: #2c2b2b;
  --origin-black: #001221;
  --sub-black: #01031b;
  --sub-black-2: #23262f;
  --orange: #f3aa3a;
  --yellow: #ffd166;
  --red: #ff6a6a;
  --space-gray: #929191;
  --greenlight: #55d434;
  --green-dark: #2d3f33;
  --base-gray: #777e90;
  --sub-greenlight: #195908;
  --blue: #2196f3;
  --blue-sky: #4bc9f0;
  --black-transparent: rgb(146 145 145 / 23%);
  --gray-neutrals: #b1b5c4;
  --greenlightSecondary: #49e454;
  --black: #000000;
  --textgreen: #23ad2f;
  --red-brown: #482928;
  --o-base-gray: rgba(119, 126, 144, 0.2);
  --greenlight-pastel: #9ffbd6;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0.07rem;
}

/* Hide scrollbar for Chrome, Safari and Opera */
html::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
html {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body {
  overflow: overlay;
}

.n-highlight {
  color: var(--textgreen);
  font-weight: 600;
}

.App {
  min-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  /* background-color: var(--origin-black); */
  background: #010707;
}

::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  color: var(--white);
  margin: 0;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

.bg-main {
  background: url("../images/markets/market-bg.png") no-repeat center;
  background-size: cover;
  min-height: inherit;
}

footer {
  padding: 40px;
  background: #000;
  color: #fff;
  text-align: center;
  font-style: italic;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: relative;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* HEADER */
.header {
  border-bottom: 1px solid #1f2931;
  transition: 0.4s all;
  z-index: 9999;
  position: fixed;
  width: 100%;
  top: auto;
  left: 50%;
  height: 74px;
  transform: translateX(-50%);
  background: rgba(4, 17, 2, 0.85);
}
.header.active {
  background-color: #000;
}

.header .container {
  position: relative;
  height: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--white);
}

.header .button-style:hover,
.header .button-style.inactive {
  opacity: 0.8;
}

.header h1 a {
  color: var(--white);
}

.header .container .logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header .container .logo .brand-name {
  text-align: center;
  font-size: 16px;
  padding-left: 18px;
}
.header .container .logo .brand-name .dao {
  font-size: 12px;
  font-weight: 700;
}
.header .container .logo .brand-name .brand {
  font-size: 14px;
  font-weight: 700;
}

.header {
  .container-header {
    width: 100%;
    max-width: 100%;
    padding: 0 60px;
    overflow: unset;
  }
}

.header nav ul li:nth-last-child(2) {
  padding-right: 4px;
}
.header nav ul li:nth-last-child(2)::after,
.header nav ul li:last-child::after {
  content: none;
}

.header nav a {
  color: var(--white);
  font-weight: 700;
}

.header .btn-connect .more.active {
  opacity: 1;
  visibility: visible;
}

.header .btn-connect .more p {
  padding-bottom: 10px;
}

.header .btn-connect .more a:hover {
  color: var(--greenlight);
}

.header .btn-connect .more p:last-child {
  padding-bottom: 0;
}

.header .link-active {
  color: var(--greenlight);
  // text-decoration: underline;
}

/* end HEADER */

/* end Home Page */

/* Introduce Page */
.introduce-page .native-title {
  position: absolute;
  bottom: 160px;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
}
.introduce-page {
  margin-bottom: -140px;
  position: relative;
  background: var(--sub-black);
}

.introduce-page .head {
  padding-top: 60px;
  display: block;
  min-height: 100vh;

  position: relative;
  background: url("../images/globe-block.png") no-repeat center bottom / contain;
}

.introduce-page .head .logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.introduce-page .head h2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  font-size: 70px;
  text-align: center;
  width: 640px;
}

.introduce-page .section-2 {
  padding: 100px 0;
}

.introduce-page .section-2.section-top .content {
  margin: 0;
}

.introduce-page .section-2 .title {
  text-align: center;
  font-size: 55px;
  font-weight: bold;
  padding-bottom: 20px;
  /* font-family: "Fredoka One", sans-serif; */
}

.introduce-page .section-2 .title-2 {
  font-size: 24px;
}

.introduce-page .section-2 .img-background {
  height: 500px;
  background: url("../images/crypto.png") no-repeat -80px 0 / 1200px 1543px;
  width: 500px;
}
.introduce-page .section-2 .wrapper {
  max-width: 1200px;
  margin: auto;
}

.introduce-page .section-2 .flex {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 30px;
}
.introduce-page .section-2 .content {
  max-width: 500px;
  font-size: 20px;
  text-align: justify;
  margin: auto;
}
.introduce-page .section-2.section-3 {
  /* padding-bottom: 160px; */
}

.introduce-page .section-2.section-3 .flex {
  flex-direction: row-reverse;
}
.introduce-page .section-2.section-3 .content {
  text-align: left;
}
.introduce-page .section-2.section-3 .img-background {
  background: url("../images/crypto.png") no-repeat -630px -470px / 1200px
    1543px;
}

.introduce-page .section-2 .flex-side {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  text-align: center;
  padding-bottom: 60px;
}

.introduce-page .section-2 .flex-side .img {
  max-width: 512px;
  height: 445px;
}
.introduce-page .section-2 .flex-side .img > img {
  object-fit: contain;
}

.introduce-page .section-2 .flex-side .label {
  font-size: 24px;
  font-weight: bold;
  padding-bottom: 14px;
  text-decoration: underline;
}
.introduce-page .section-2 .flex-side .content {
  text-align: center;
}
.introduce-page .section-2 .flex-side .left {
}
.introduce-page .section-2 .flex-side .right {
}

.introduce-page .section-2 .last {
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}

.introduce-page .section-2 .last p {
  padding-bottom: 4px;
}

.introduce-page .tokenomic {
  padding: 0 0 100px;
}

.introduce-page .social-media {
}
.introduce-page .social-media .title {
  text-align: center;
  font-size: 55px;
  font-weight: bold;
  padding-bottom: 20px;
  /* font-family: "Fredoka One", sans-serif; */
  display: none;
}
.introduce-page .social-media .list-social {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
  display: flex;
  flex-direction: column;
  height: 420px;
  justify-content: space-around;
  align-items: flex-end;
  padding-top: 50px;
  padding-bottom: 100px;
}
.introduce-page .social-media .list-social img {
  width: 70px;
  height: 70px;
}
.introduce-page .social-media .list-social .link {
  transition: 0.4s all;
}
.introduce-page .social-media .list-social .link:hover {
  transform: rotate(20deg);
}

.g2-tooltip {
  color: #000;
}
.g2-tooltip-list-item,
.g2-tooltip-value,
.g2-tooltip-name {
  color: #000;
}

.g2-tooltip-list-item {
  text-align: center;
}

/* end Introduce Page */

/* Portforlio Page */

.flex-vertical {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.portfolio .total {
  text-align: center;
}

.container.portfolio {
  max-width: 1200px;
}

.container.portfolio #tab-view {
  margin-top: 46px;
}

.container.portfolio #tab-view .ant-tabs-nav {
  margin: 0;
}

.portfolio .balance-token {
  padding-bottom: 0;
}

.portfolio .balance-token .deposit-tab {
  padding-bottom: 60px;
}

.portfolio .balance-token .big-title {
  font-size: 28px;
  font-weight: 600;
  display: block;
  margin: 10px auto 0;
  padding: 0 0 20px;
  text-align: center;
}
.portfolio .balance-token .big-title span {
  font-weight: 600;
}

.portfolio .token-list {
  position: relative;
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  border-radius: 5px;
  min-height: 200px;
}

.portfolio .token-list::before {
  /* content: ""; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1px;
  height: 85%;
  background-color: #20948a;
}

.portfolio .token-list .side {
  width: 100%;
}
.portfolio .token-list .side .my-info {
  display: flex;
  justify-content: space-between;
  padding: 10px 0 20px;
  border-bottom: 1px solid #20948a;
}

.portfolio .token-list .side .my-info h5 {
  font-weight: bold;
}

.portfolio .token-list .side .detail {
  overflow: hidden;
  border-radius: 16px;
  background-color: #112c25;
}
.portfolio .token-list .side .detail .label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 0 24px;
  /* pointer-events: none;*/
}

.portfolio .token-list .side .detail .label.label__title {
  background-color: #041814;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0 20px;
  min-height: 80px;
}

.portfolio .token-list .label .title {
  width: 140px;
  color: var(--white);
  font-size: 15px;
  font-weight: bold;
}

.portfolio .token-list .nft-deposit-item {
}
.portfolio .token-list .wrap-info {
  position: relative;
  // margin-bottom: 24px;
  border-bottom: 1px solid rgba(61, 84, 77, 0.3);
}
.portfolio .token-list .wrap-info:last-child {
  border-bottom: none;
}
.portfolio .token-list .wrap-info .arrow-down {
  position: absolute;
  bottom: 14px;
  left: 50%;
  transform: translateX(-50%);
}
.portfolio .token-list .wrap-info .arrow-down.active {
  transform: translateX(-50%) rotate(180deg);
}
.portfolio .token-list .wrap-info .label.label__token__detail {
  display: none;
}
.portfolio .token-list .wrap-info.active .label.label__token__detail {
  display: block;
  margin-top: 0;
}

.portfolio .label.label__token__detail .token__detail__row {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 7px;
  align-items: center;
  position: relative;
}

.token__detail__row .btn-plus {
  cursor: pointer;
  position: relative;
  font-size: 24px;
  /* right: 60px; */
  width: 50px;
  height: 32px;
}
.token__detail__row .btn-plus::before {
  content: "+";
  position: absolute;
  top: -5px;
  /* left: 50%; */
  transform: translateX(-50%);
}

.token__detail__row .btn-minus {
  cursor: pointer;
  position: relative;
  font-size: 24px;
  right: 0;
  width: 50px;
  height: 32px;
}

.token__detail__row .btn-minus::before {
  content: "-";
  position: absolute;
  top: -6px;
  /* left: 50%; */
  transform: translateX(-50%);
}

.portfolio .label.label__token__detail .token__detail__row .title {
  text-align: left;
}
.portfolio .label.label__token__detail .token__detail__row .label__token-mini {
  text-align: left;
  font-size: 20px;
}

.portfolio .token__logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  // max-width: 140px;
}

.portfolio .token__logo img {
  width: 32px;
  height: 32px;
}
.portfolio .token__logo .img {
  margin-right: 8px;
  border: 1px solid #04413c;
  border-radius: 50%;
  min-width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: var(--white);
}

.portfolio .token__price p {
  text-align: left;
  font-size: 14px;
  color: var(--base-gray);
  /* word-break: break-all; */
}

.portfolio .token-list .side .detail .label.label__token {
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 2px 30px;
  /* cursor: pointer; */
}
.portfolio .token-list .side .detail .label.label__token:hover {
  /* background-color: #fff; */
}

.portfolio .label__token-mini {
  width: 140px;
  font-size: 16px;
}

.portfolio .label__token-mini.label__token-mini__collateral {
  display: flex;
  align-items: center;
  justify-content: center;
}

.portfolio .label__token-mini.label__token-mini__actions {
  height: 74px;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  display: flex;
}

.portfolio .label__token-mini.label__token-mini__actions .button-basic {
  font-size: 12px;
  margin: 5px 0;
  padding: 10px 14px;
}

.value-collateral {
  margin: 0 14px;
}

.portfolio .nft-deposit-item .token__price p,
.portfolio .nft-deposit-item .label__token-mini .token_name {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.portfolio .label__token-mini .token_name {
  font-weight: bold;
  width: 160px;
  word-break: break-word;
  font-size: 16px;
  color: var(--white);
}

.portfolio .label__token-mini .token_name.nft_name {
  text-transform: capitalize;
}

.portfolio .label__token-mini .token_name .token_name_icon {
  position: relative;
  top: -2px;
  display: inline;
}

.portfolio .empty-account-line {
  border-radius: 4px;
  margin: 23px auto 24px;
  text-align: center;
}
/* end Portforlio Page */

/* Balance Page */
.total-balance .wrap-total {
  display: flex;
  justify-content: space-between;
}
// .total-balance .total {
//   padding: 14px 24px;
//   width: 245px;
//   border-radius: 12px;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   background: #102520;
// }

.total-balance .total {
  padding: 14px 24px;
  width: 49%;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #102520;
  position: relative;
}

.total-balance .total::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1px;
  height: 70%;
  background-color: #1c4c2a;
}

.total-balance .total .total-value {
  width: 245px;
}

.total-balance .total .title {
  font-size: 16px;
  line-height: 30px;
  font-weight: bold;
  color: whitesmoke;
}
.total-balance .total .title.text-yellow {
  color: var(--yellow);
}
.total-balance .total .value {
  font-size: 32px;
  font-weight: bold;
}
.total-balance .total .value.health-factor {
  color: #23ad2f;
}
.total-balance .total .value.health-factor.text-yellow {
  color: var(--yellow);
}
/* END Balance Page */

/* marketplace Page */

.marketplace {
  padding-top: 74px;
  // height: 100vh;
  background: #0e0d1a;
}

.marketplace .single-slide {
  height: 40vh;
  color: "#fff";
}
/* .marketplace .single-slide-1 {
  background: url("./images/nft_circle.jpeg") no-repeat center 70% / cover;
}
.marketplace .single-slide-2 {
  background: url("./images/nft_city.jpeg") no-repeat center 70% / cover;
}
.marketplace .single-slide-3 {
  background: url("./images/nft_moutain-land.jpeg") no-repeat center 70% / cover;
} */

.marketplace .ant-carousel .slick-dots-bottom {
  bottom: -38px;
}

.marketplace .list-card {
  background-color: var(--origin-black);
  position: relative;
  padding: 74px 0;
}
.marketplace .list-card .container {
  max-width: 1200px;
}
.marketplace .wrap-card {
  display: grid;
  grid-template-columns: repeat(auto-fill, 280px);
  grid-row-gap: 40px;
  grid-column-gap: 25px;
  justify-content: center;
}
.marketplace .wrap-card .card {
  cursor: pointer;
  max-width: 280px;
  padding: 12px;
  border-radius: 6px;
  border: 1px solid var(--origin-black);
  transition: 0.4s all;
}
.marketplace .wrap-card .card:hover {
  border: 1px solid var(--greenlight);
}
.marketplace .wrap-img {
  position: relative;
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
}
.marketplace .wrap-img .img {
  width: 100%;
  height: 240px;
}
.marketplace .wrap-img .img img {
  object-fit: cover;
  width: inherit;
  height: inherit;
}
.marketplace .wrap-img .img video {
  overflow: hidden;
  width: inherit;
  height: auto;
}

.marketplace .wrap-img .type {
  z-index: 1;
  position: absolute;
  top: 12px;
  left: 12px;
  color: rgb(255, 255, 255);
  background-color: rgba(0, 0, 0, 0.6);
  padding: 5px 12px;
  border-radius: 5px;
  font-size: 11px;
}

.marketplace .wrap-img .like {
  z-index: 1;
  position: absolute;
  bottom: 12px;
  right: 12px;
  color: rgb(255, 255, 255);
  background-color: rgba(0, 0, 0, 0.6);
  padding: 5px 12px;
  border-radius: 5px;
  font-size: 11px;
}

.marketplace .wrap-card .card-name {
  padding: 10px 0 14px;
}
.marketplace .wrap-card .card-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
}
.marketplace .wrap-card .card-price .price {
  display: flex;
  align-items: center;
}
.marketplace .wrap-card .card-price .left {
  font-size: 12px;
}
.marketplace .wrap-card .card-price .right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.marketplace .wrap-card .card-price .price-token {
}
.marketplace .wrap-card .card-price .price-token {
  padding-left: 8px;
}
.marketplace .wrap-card .card-price .icon-token {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
  background-color: var(--black-transparent);
}
.marketplace .wrap-card .price-text {
  align-self: flex-start;
}
.marketplace .wrap-card .card-price .left {
}
.marketplace .wrap-card .card-price .right {
}

.marketplace .wrap-card .price-usd {
  font-size: 12px;
  display: flex;
  align-items: center;
  height: 21px;
}

.marketplace .wrap-card .creator-verified {
  padding-right: 4px;
  color: var(--blue);
  font-size: 12px;
}

/* end marketplace Page */

/* nft-detail Page */
.nft-detail {
  min-height: 100vh;
  position: relative;
  color: var(--white);
  background-color: var(--origin-black);
}

.nft-detail .nft-info {
  padding-top: 194px;
  position: relative;
}

.nft-detail .nft-info .arrow-back {
  color: var(--white);
  font-size: 70px;
  position: absolute;
  top: -100px;
  left: 0;
  cursor: pointer;
}
.nft-detail .nft-info .container::after {
  content: "";
  display: block;
  clear: both;
}
.nft-detail .nft-info .container {
  max-width: 1400px;
  margin: auto;
}
.nft-detail .nft-info .nft-name {
  font-size: 24px;
  margin: 8px 8px 0 0;
}
.nft-detail .nft-info .nft-type {
  background: gray;
  padding: 4px;
  border-radius: 6px;
  margin: 8px 8px 0 0;
  font-size: 12px;
}
.nft-detail .nft-info .wrap-img {
  /* overflow: hidden; */
  border-radius: 6px;
  height: 700px;
  width: 60%;
  margin: 0 5% 0 0;
  float: left;
}
.nft-detail .nft-info .wrap-img .img {
  height: inherit;
  width: 100%;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0px -2px 7px #2cffe3, 0px 2px 8px #2cffe4, 0px 0px 7px #2cffe4;
  border: 3px solid #6cffec;
}
.nft-detail .nft-info .wrap-img .img .video-background {
  width: 100%;
  height: 100%;
}
.nft-detail .nft-info .wrap-detail {
  position: relative;
  width: 35%;
  float: right;
  min-height: 700px;
  max-height: 700px;
  overflow-y: scroll;
}

.nft-detail .nft-info .wrap-detail .wrap-button {
  width: 100%;
  position: absolute;
  bottom: auto;
  left: auto;
  padding: 10px 0 0;
  background: var(--origin-black);
}

.nft-detail .nft-info .wrap-detail .wrap-button .price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.nft-detail .wrap-button .price .token-icon-price {
  text-transform: uppercase;
  padding: 0 10px;
  font-size: 20px;
}
.nft-detail .wrap-button .price .token-usd {
}

.nft-detail .nft-info .wrap-detail .wrap-button .button {
  cursor: pointer;
  width: inherit;
  padding: 8px;
  background: var(--blue);
  font-size: 24px;
  text-transform: uppercase;
  border: none;
  border-radius: 4px;
  transition: 0.4s all;
}

.nft-detail .nft-info .wrap-detail .wrap-button .button:hover {
  opacity: 0.84;
}

.nft-detail .nft-info .wrap-detail .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.nft-detail .nft-info .wrap-detail .top .title {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.nft-detail .nft-info .wrap-detail .top .like-share {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 8px 8px 0 0;
}
.nft-detail .nft-info .wrap-detail .top .like-share-icon {
  cursor: pointer;
  margin-right: 8px;
}
.nft-detail .nft-info .wrap-detail .top .like-share .like {
  border: 1px solid #fff;
  border-radius: 6px;
  padding: 2px 8px;
}
.nft-detail .nft-info .wrap-detail .top .like-share .share {
  font-size: 22px;
}

.nft-detail .owner-info {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-bottom: 24px;
}
.nft-detail .owner-info .wrap-owner {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 34px;
  padding-top: 25px;
}
.nft-detail .owner-info .wrap-owner .token-icon {
  padding-right: 8px;
}
.nft-detail .owner-info .wrap-owner .owner {
  /* padding-left: 12px; */
}

.nft-detail .owner-info .wrap-owner .owner-title {
  color: var(--space-gray);
  font-size: 12px;
}
.nft-detail .owner-info .wrap-owner .owner-name {
  font-size: 14px;
}

.nft-detail .wrap-detail .wrap-description {
  padding: 20px 0 24px;
  margin: 14px 0;
  border-bottom: 1px solid var(--space-gray);
  border-top: 1px solid var(--space-gray);
}
.nft-detail .wrap-detail .description {
  max-height: 100px;
  overflow-y: scroll;
}

.nft-detail .wrap-comtfee {
  padding-top: 45px;
  padding-bottom: 40px;
}
.nft-detail .comtfee-item {
  border-bottom: 1px solid #fff;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.nft-detail .comtfee-info {
  display: flex;
  justify-items: center;
  align-items: center;
}
.nft-detail .comtfee-avatar {
  width: 46px;
  height: 46px;
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid var(--greenlight);
  position: relative;
  top: 10px;
}
.nft-detail .comtfee-avatar img {
  object-fit: cover;
  width: 46px;
  height: 46px;
}
.nft-detail .comtfee-name {
  font-size: 17px;
  margin: 0 8px;
}
.nft-detail .comtfee-tranfer {
  font-size: 17px;
  font-style: italic;
  color: gray;
}
.nft-detail .comtfee {
  position: relative;
  margin-bottom: 20px;
}

.nft-detail .comtfee .button {
}
.nft-detail .comtfee .ant-input:focus,
.nft-detail .comtfee .ant-input-focused {
  border-color: var(--greenlight);
  box-shadow: unset;
  padding: 10px;
}
.nft-detail .comtfee .comtfee-input {
  background: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid gray !important;
  margin-bottom: 55px;
  color: var(--white);
}

.nft-detail .comtfee-button {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}
.nft-detail .comtfee-button button {
  padding: 6px 14px;
}
.nft-detail .comtfee-tooltip {
  font-size: 19px;
  margin-right: 10px;
}
.nft-detail .comtfee-tranfer .comtfee-txt-normal {
  font-style: normal;
  color: var(--white);
}
.nft-detail .comtfee-text {
  padding-bottom: 10px;
  margin-top: -3px;
  margin-left: 67px;
}

@media screen and (max-width: 1400px) {
  .nft-detail .nft-info .container {
    margin: 0 40px;
  }
}

@media screen and (max-width: 1024px) {
  .nft-detail .nft-info .wrap-img,
  .nft-detail .nft-info .wrap-detail {
    min-height: 500px;
    height: 500px;
  }
  .nft-detail .nft-info .wrap-img {
    width: 55%;
  }
  .nft-detail .nft-info .wrap-detail {
    width: 40%;
  }
}

@media screen and (max-width: 974px) {
  .nft-detail .nft-info .container {
    margin: 0 30px;
  }
  .nft-detail .nft-info .wrap-img {
    width: 55%;
  }
  .nft-detail .nft-info .wrap-detail {
    width: 40%;
  }
}

@media screen and (max-width: 900px) {
  .nft-detail .nft-info .wrap-detail,
  .nft-detail .nft-info .wrap-img {
    float: none;
    width: 100%;
  }
  .nft-detail .nft-info .wrap-img {
    margin-bottom: 8px;
    height: 480px;
  }
  .nft-detail .nft-info .wrap-detail {
    min-height: auto;
    height: auto;
    max-height: auto;
    padding-bottom: 140px;
  }

  .nft-detail .nft-info .wrap-detail .wrap-button {
    position: fixed;
    width: 100%;
    padding: 0 30px 30px;
  }
}

@media screen and (max-width: 600px) {
  .nft-detail .nft-info .wrap-detail .top .like-share,
  .nft-detail .nft-info .nft-name {
    width: 100%;
  }
  .nft-detail .nft-info .wrap-detail .top .like-share {
    margin-top: 12px;
  }
}

@media screen and (max-width: 290px) {
  .nft-detail .nft-info .nft-type {
    margin: 10px 0 20px 0px;
  }
}

/* end nft-detail Page */

/* BACKGROUND WAVE */

.waveWrapper {
  z-index: 0;
  overflow: hidden;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
}
.waveWrapperInner {
  position: absolute;
  width: 100%;
  overflow: hidden;
  height: 100%;
  bottom: -1px;
  background: var(--origin-black);
  /* background: -moz-linear-gradient(180deg, #0c0817 0%, #150a33 100%);
  background: -webkit-linear-gradient(180deg, #0c0817 0%, #150a33 100%);
  background: linear-gradient(180deg, #0c0817 0%, #150a33 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0c0817",endColorstr="#150a33",GradientType=1); */
  /* background: rgb(0, 0, 0);
  background: -moz-linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(3, 9, 46, 1) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(3, 9, 46, 1) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(3, 9, 46, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#03092e",GradientType=1); */
}
.bgTop {
  z-index: 15;
  opacity: 0.2;
}
.bgMiddle {
  z-index: 10;
  opacity: 0.45;
}
.bgBottom {
  z-index: 5;
  opacity: 0.4;
}
.wave {
  width: auto;
  position: absolute;
  left: 0;
  width: 200%;
  height: 100%;
  background-repeat: repeat no-repeat;
  background-position: 0 bottom;
  transform-origin: center bottom;
}
.waveTop {
  background-size: 50% 140px;
}
.waveAnimation .waveTop {
  animation: move-wave 3s;
  -webkit-animation: move-wave 3s;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.waveMiddle {
  background-size: 50% 140px;
}
.waveAnimation .waveMiddle {
  animation: move_wave 10s linear infinite;
}
.waveBottom {
  background-size: 50% 140px;
}
.waveAnimation .waveBottom {
  animation: move_wave 15s linear infinite;
}

@keyframes move_wave {
  0% {
    transform: translateX(0) translateZ(0) scaleY(1);
  }
  50% {
    transform: translateX(-25%) translateZ(0) scaleY(0.55);
  }
  100% {
    transform: translateX(-50%) translateZ(0) scaleY(1);
  }
}
/* end BACKGROUND WAVE */

/* POPUP-CSS */
/* POPUP-CSS */
/* POPUP-CSS */
.wrap-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(0 0 0 / 40%);
  z-index: 9999;
}

.wrap-popup .popup {
  color: var(--white);
  position: absolute;
  z-index: 99999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--origin-black);
  background-color: var(--sub-black-2);
  width: 400px;
  border-radius: 10px;
  overflow: hidden;
  animation: popup 0.1s ease;
  padding: 0 0 24px;
}

.wrap-popup .popup.notification {
  background: var(--sub-black-2);
  border-radius: 8px;
  min-height: 120px;
  width: 450px;
  padding: 24px;
  text-align: center;
}

.popup.notification .text-notification {
  font-weight: 600;
  white-space: pre-line;
  font-size: 16px;
}

.popup.notification .text-notification strong {
  font-size: 15px;
}

.popup.notification .wrap-button {
  display: flex;
  justify-content: space-between;
  padding: 15px 0 0;
}

.button-notification {
  cursor: pointer;
  text-transform: capitalize;
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;
  /* BG Green */
  background: linear-gradient(
      180deg,
      rgba(84, 169, 67, 0) 0%,
      rgba(84, 169, 67, 0.2) 100%
    ),
    rgba(84, 169, 67, 0.1);
  border-radius: 6px;
  border: 1px solid var(--greenlight);
  font-weight: 700;
  font-size: 13px;
  width: 160px;
  margin: 0 6px;
  transition: 0.4s all;
}
.popup.notification .title-notification {
  text-transform: uppercase;
  font-size: 20px;
}

.button-notification.button-gray {
  background: var(--space-gray);
  border: 1px solid var(--space-gray);
}
.button-notification:hover {
  opacity: 0.84;
}

.popup .popup-available-price {
  font-size: 15px;
}

@keyframes popup {
  0% {
    transform: translate(-50%, 0%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}

.wrap-popup .popup .icon-close {
  cursor: pointer;
  padding: 16px;
  position: absolute;
  right: 14px;
  top: 14px;
  z-index: inherit;
  background: rgb(0 0 0 / 60%);
  border-radius: 50%;
  width: 14px;
  height: 14px;
}

.wrap-popup .popup .icon-close img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.wrap-popup .popup .title {
  width: 80%;
  color: var(--greenlight);
  margin: 0 24px;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.1rem;
  padding-top: 14px;
  padding-bottom: 20px;
  position: relative;
  text-transform: capitalize;
}

.wrap-popup .popup .title .title-tooltip {
  font-size: 21px;
}

.wrap-popup .popup .title.yellow {
  color: var(--yellow);
}

.yellow {
  color: var(--yellow);
}
.bg-yellow {
  background: rgba(255, 209, 102, 0.2) !important;
  border: 1px solid #ffd166 !important;
}

.wrap-popup .popup .icon {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  background-color: var(--white);
}

.wrap-popup .popup .wrap-price {
  max-width: 180px;
  padding-left: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;
}

.wrap-popup .popup .wrap-price .price {
  color: var(--base-gray);
  font-size: 12px;
}

.wrap-popup .popup .wrap-icon-name {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 24px 16px;
  border: 1px solid var(--o-base-gray);
  border-radius: 6px;
  padding: 11px 16px;
}

.wrap-popup .popup .icon-name {
  font-size: 14px;
  position: relative;
  color: var(--white);
  font-weight: bold;
  text-transform: capitalize;
  max-width: 180px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.wrap-popup .popup .available {
  margin: 24px 24px 4px;
  font-size: 14px;
  color: var(--base-gray);
}

.wrap-popup .popup .available span {
  font-size: 12px;
}

.wrap-popup .popup .wrap-input-number {
  position: relative;
  padding: 10px 0;
  width: auto;
  border-radius: 4px;
  font-size: 15px;
  font-weight: bold;
  outline: none;
  margin: -10px 24px 0;
}

// .wrap-popup .popup .input-number {
//   position: relative;
//   width: 100%;
//   border-radius: 4px;
//   font-size: 15px;
//   font-weight: bold;
//   outline: none;
// }

.wrap-popup .popup .value-percent {
  color: #1acab3;
  position: relative;
  text-align: center;
  padding-top: 4px;
  padding-bottom: 14px;
}
.wrap-popup .popup .wrap-white {
  z-index: 9;
  background-color: transparent;
  margin: 0 24px;
}

.wrap-popup .wrap-error-popup {
  min-height: 36px;
  position: relative;
  margin: 10px 0;
  background: transparent;
  border-radius: 4px;
  padding: 7px 7px 7px 30px;
}

.wrap-popup .wrap-error-popup.error {
  background: #482928;
}

.wrap-popup .wrap-error-popup .text-error {
  position: relative;
  margin: 0 auto;
  word-break: break-word;
  background-color: #482928;
  color: var(--white);
  // padding: 5px 5px 5px 30px;
}

.wrap-popup .wrap-error-popup .icon-error {
  position: absolute;
  top: 50%;
  left: 8px;
  z-index: 1;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  height: 22px;
  width: 22px;
}

.wrap-popup .popup .info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  position: relative;
  color: var(--black);
  font-weight: bold;
  padding: 14px !important;
}
.wrap-popup .popup .info p {
  color: var(--black);
  /* width: 50%; */
}

.wrap-popup .popup .input-number {
  position: relative;
  padding: 10px 0;
  width: 100%;
  border: 3px solid transparent;
  border-radius: 4px;
  font-size: 15px;
  font-weight: bold;
  outline: none;
}

.wrap-popup .popup .input-number.input-number-error {
  border: 3px solid #e30505;
}

.wrap-popup .popup .input-number .ant-input-number-handler {
  display: none;
}
.wrap-popup .popup .ant-input-number-input {
  height: auto;
  width: 84%;
}

.wrap-popup .popup .slider-range {
  position: relative;
  width: auto;
  margin: 16px 24px 44px;
}

.wrap-popup .popup .slider-range .ant-slider-mark-text {
  font-size: 12px;
  display: none;
}
.wrap-popup .popup .slider-range .ant-slider-mark-text:last-child,
.wrap-popup .popup .slider-range .ant-slider-mark-text:first-child {
  display: block;
  color: var(--white);
  margin: 4px;
}

.wrap-popup .popup .wrap-nft-health {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.wrap-popup .popup .wrap-nft-health .nft-health-title {
  font-size: 14px;
  color: var(--base-gray);
}
.wrap-popup .popup .wrap-nft-health .nft-health-number {
  font-size: 14px;
  font-weight: bold;
  color: var(--red);
}

.wrap-popup .popup .wrap-nft-health .nft-health-number.red {
  color: var(--red);
}

.wrap-popup .popup .wrap-nft-health .nft-health-number.orange {
  color: var(--orange);
}

.wrap-popup .popup .wrap-nft-health .nft-health-number.green {
  color: var(--textgreen);
}

.wrap-popup .popup .wrap-nft-health #slider-range .ant-slider-handle {
  z-index: 9;
}
.wrap-popup .wrap-warning-nft {
  max-width: 350px;
  display: flex;
  margin: 24px;
  margin-bottom: 0;
  justify-content: space-between;
  align-items: center;
  background: var(--red-brown);
  border-radius: 8px;
  padding: 8px;
}

.wrap-popup .wrap-warning-nft .txt {
  font-size: 14px;
  margin-left: 12px;
  letter-spacing: 0.5px;
}

#slider-range .ant-slider-handle:focus {
  box-shadow: 0 0 0 5px rgb(85 212 52 / 10%) !important;
}
#slider-range .ant-slider-track {
  z-index: 3;
}
// normal
#slider-range .ant-slider-track,
#slider-range .ant-slider-handle,
#slider-range .ant-slider-dot-active,
.wrap-popup .popup .ant-slider-dot-active {
  background: var(--greenlight) !important;
}

#slider-range .ant-slider-handle,
#slider-range .ant-slider-dot-active,
.wrap-popup .popup .ant-slider-dot-active {
  border-color: var(--greenlight);
}
// yellow
#slider-range.yellow .ant-slider-track,
#slider-range.yellow .ant-slider-handle,
#slider-range.yellow .ant-slider-dot-active,
.wrap-popup.yellow .popup .ant-slider-dot-active {
  background: var(--yellow) !important;
}

#slider-range.yellow .ant-slider-handle,
#slider-range.yellow .ant-slider-dot-active,
.wrap-popup.yellow .popup .ant-slider-dot-active {
  border-color: var(--yellow);
}

#slider-range.yellow .ant-slider-handle:focus {
  box-shadow: 0 0 0 5px rgb(212 202 52 / 10%) !important;
}

#slider-range .ant-slider-step {
  background: var(--base-gray);
}

#slider-range .ant-slider-dot {
  background: var(--base-gray);
  border: none;
  width: 14px;
  height: 14px;
  top: -5px;
}

.ant-slider:hover .ant-slider-handle,
.wrap-popup .popup .slider-range .ant-slider-handle.ant-tooltip-open {
  background: var(--greenlight);
  border-color: #55d434;
}

#input-range-max {
  position: absolute;
  top: 50%;
  right: -30px;
  transform: translate(-50%, -50%);
  color: black;
  // background-color: var(--white);
  cursor: pointer;
  padding: 6px 13px 6px 13px;
}

.popup .ant-slider-handle + .ant-slider-step .ant-slider-tooltip {
  display: none;
}

.popup .ant-slider-handle:focus + .ant-slider-step .ant-slider-tooltip {
  display: block;
}

.wrap-popup .popup .total-available {
  font-size: 14px;
  font-weight: 600;
  margin: 0 24px 0;
  color: var(--white);
  border-bottom: 1px solid var(--base-gray);
  padding-bottom: 12px;
}

.wrap-popup .popup .total {
  position: relative;
  text-align: center;
  color: var(--black);
  font-weight: bold;
  padding-bottom: 16px;
  font-size: 16px;
}

.wrap-popup .popup .rates-title {
  color: var(--white);
  font-size: 16px;
  border-top: 1px solid var(--o-base-gray);
  padding-top: 16px;
  padding-bottom: 4px;
}
.wrap-popup .popup .flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: var(--base-gray);
  margin-top: 6px;
}

.wrap-popup .popup .flex .safe-health-factor {
  color: var(--greenlightSecondary);
}
.wrap-popup .popup .flex .danger-health-factor {
  color: var(--yellow);
}

.wrap-popup .popup .btn {
  width: 100%;
  text-align: center;
  padding: 8px 14px;
  outline: none;
  border-radius: 8px;
  margin: auto;
  font-size: 18px;
  font-weight: bold;
  background-color: var(--green-dark);
  border: 1px solid var(--greenlight);
  color: var(--white);
  opacity: 1;
  transition: 0.4s all;
}
.wrap-popup .popup .btn:hover {
  cursor: pointer;
  background-color: rgb(45 63 51 / 60%);
}

.wrap-popup .popup .btn.yellow {
  background: rgba(255, 209, 102, 0.2);
  border: 1px solid rgba(255, 209, 102);
}
.wrap-popup .popup .btn.yellow:hover {
  background: rgba(255, 209, 102, 0.12);
}

.wrap-popup .popup .btn:active {
  opacity: 0.7;
}

.wrap-popup .popup .btn.disable {
  background-color: #353945;
  border: 1px solid #353945;
  color: var(--base-gray);
  cursor: not-allowed;
}

.wrap-popup .popup .btn.disable:hover {
  background-color: #353945;
}

.wrap-popup .popup .wrap-price-apy {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.wrap-popup .popup .wrap-price-apy .apy-number {
  font-size: 14px;
  font-weight: 500;
}

.wrap-popup .text-error {
}

.wrap-popup .popup .wrap-maturity {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 36px 24px 10px;
}
.wrap-popup .popup .wrap-maturity button {
  cursor: pointer;
  border: 1px solid var(--base-gray);
  border-radius: 16px;
  color: var(--base-gray);
  background-color: transparent;
  padding: 4px 6px;
  margin-left: 8px;
}

.wrap-popup .popup .wrap-maturity button.active {
  border: 1px solid var(--yellow);
  background-color: var(--yellow);
  color: var(--sub-black-2);
}

.wrap-popup.nft-deposit-popup {
}

.wrap-popup.nft-deposit-popup .popup {
  min-height: 400px;
  background: #fff;
  border: 1px solid var(--greenlight);
  border-radius: 8px;
}

.wrap-popup.nft-deposit-popup .wrap-img {
  background-color: var(--origin-black);
  height: 450px;
}

.wrap-popup.nft-deposit-popup .wrap-img .img {
  height: 100%;
}

.wrap-popup.nft-deposit-popup .popup .name {
  color: var(--origin-black);
  text-align: center;
  padding: 10px 0;
}

.wrap-popup.nft-deposit-popup .nft-row > * {
  color: var(--origin-black);
}

.wrap-popup.nft-deposit-popup .nft-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
}

.nft-deposit-popup .wrap-img .img video {
  overflow: hidden;
  width: inherit;
  height: auto;
}

.nft-deposit-popup .wrap-img .img .video-background {
  height: 100%;
  max-width: 400px;
}

.wrap-popup.nft-deposit-popup .popup .btn {
  margin: 34px auto 20px;
}

/* end POPUP-CSS */
/* end POPUP-CSS */
/* end POPUP-CSS */

/* Switch-CSS */
.switch {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 20px;
}

.switch .ant-switch {
  background-color: #e9e9ea !important;
}

.switch .ant-switch-checked {
  background-color: var(--greenlight) !important;
}
.switch .ant-switch-checked:focus {
  box-shadow: none;
}

.switch .input-slider {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider.round {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider.round:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.input-slider:checked + .slider.round {
  background-color: #2196f3;
}

.input-slider:focus + .slider.round {
  box-shadow: 0 0 1px #2196f3;
}

.input-slider:checked + .slider.round:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
/* end Switch-CSS */

/* WAVE-CSS */
.Ocean {
  background: #fff;
  top: 245px;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: inherit;
  height: inherit;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  /* z-index: ; */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.Wave::-webkit-scrollbar,
.Ocean::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.Ocean,
.Wave {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.Wave {
  width: 6800px;
  animation-name: swell;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  fill: var(--white);
}

.Wave .WavePath {
  width: 100%;
}

/* end WAVE-CSS */

@keyframes swell {
  /* 0% {
    transform: translateX(-50%);
  }
  25% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-50%);
  }
  75% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  } */

  0% {
    transform: translateX(-50%);
  }

  100% {
    transform: translateX(0%);
  }
}

.roadmap {
  padding: 80px 20px 0px;
  overflow-x: hidden;
}

.roadmap .title {
  text-align: center;
  font-size: 55px;
  font-weight: bold;
  padding-bottom: 20px;
  /* font-family: "Fredoka One", sans-serif; */
}

.roadmap .section-top .text {
  padding: 15px 0;
}

.roadmap .ship-1 {
  position: absolute;
  top: -66px;
  left: -10px;
  -webkit-animation: mover 1.5s infinite alternate;
  animation: mover 1.5s infinite alternate;
}
.roadmap .section-top .col {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.roadmap .section-top .col .content {
  position: relative;
  width: 340px;
  padding-left: 30px;
  margin: 40px 0 0;
}

.roadmap .section-top .col .content.number::before {
  content: "1";
  position: absolute;
  top: -6px;
  left: 0;
  font-size: 24px;
  color: var(--yellow);
  font-weight: bold;
}

.roadmap .section-top .col .content.number.number-2::before {
  content: "2";
}

.roadmap .section-top .col .content.number.number-3::before {
  content: "3";
}

.roadmap .section-top .col .content.number.number-4::before {
  content: "4";
}

.roadmap .section-bot {
  padding: 80px 0 80px;
}

.roadmap .deco-stones {
  position: absolute;
  right: 0;
  bottom: 400px;
}
.roadmap .deco-big {
  position: absolute;
  top: -30px;
  left: -400px;
  width: 1410px;
  height: 1034px;
  /* background: url("/assets/bg-deco.png") no-repeat center center / contain; */
}

.roadmap .ship-2 {
  position: absolute;
  top: 60px;
  right: 0;
  -webkit-animation: mover 1.5s infinite alternate;
  animation: mover 1.5s infinite alternate;
}

.roadmap .wrap-line::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 1px;
  height: 100%;
  background: var(--greenlight);
}
.roadmap .wrap-line .phase {
  position: relative;
  width: 270px;
  margin-bottom: 60px;
}

.roadmap .wrap-line .phase.left {
  margin-left: auto;
}
.roadmap .wrap-line .phase.right {
  text-align: right;
}

.roadmap .wrap-line .phase.right h4 {
  left: auto;
  right: -170px;
  margin-left: auto;
}

.roadmap .wrap-line .phase h4 {
  /* position: absolute; */
  font-weight: lighter;
  width: 100px;
  text-align: center;
  margin-bottom: 14px;
  /* left: -180px; */
  border: 1px solid var(--white);
  padding: 6px 15px;
  border-radius: 20px;
}
.roadmap .wrap-line .phase h5 {
  padding-bottom: 24px;
  font-size: 20px;
  letter-spacing: 2px;
}
.roadmap .wrap-line .phase .content p {
  font-weight: lighter;
  font-weight: normal;
  font-size: 16px;
}

.roadmap .wrap-line .wrap {
  position: relative;
}

.roadmap .wrap-line .wrap:last-child::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--sub-black);
  width: 100%;
  height: 100%;
}

.roadmap .wrap-line .wrap .planet {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.roadmap .wrap-line .wrap .planet.grayscale {
  filter: gray;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

// TABS ANT
.ant-tabs-top > .ant-tabs-nav::before {
  content: none !important;
}

.ant-tabs-tab-btn {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--greenlightSecondary);
}

.ant-tabs-ink-bar {
  color: var(--greenlightSecondary);
  background: var(--greenlight);
}

// Ant Tooltip

.ant-tooltip-inner {
  padding: 20px;
  // min-width: 300px;
}

/* REUSE */
/* REUSE */
/* REUSE */
/* REUSE */
/* REUSE */

.text-error {
  color: var(--red);
  font-size: 13px;
  text-align: center;
  width: 100%;
}

.button-basic {
  cursor: pointer;
  pointer-events: all;
  padding: 6px;
  background: #15310d;
  border: 1px solid var(--greenlight);
  color: #fff;
  border-radius: 8px;
  transition: 0.4s all;
  width: 94px;
  font-size: 13px;
}

.button-basic.button-basic-withdraw {
  background: #3d8f1e;
  border: 1px solid #99ff72;
  color: white;
}

.button-basic.button-basic-borrow {
  background: rgba(255, 209, 102, 0.2);
  color: white;
  border: 1px solid #ffd166;
}

.button-basic:hover {
  /* opacity: 0.8; */
  color: #000;
  background-color: #fff;
}
.button-basic:active {
  opacity: 0.6;
}

.color-white {
  color: var(--white);
}

.color-space-gray {
  color: var(--space-gray);
}

.fwb {
  font-weight: bold;
}

.tac {
  text-align: center;
}

.tar {
  text-align: right;
}

.position-relative {
  position: relative;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

.bg-white {
  background-color: #fff;
}
.padding-horizontal-14 {
  position: relative;
  padding: 0 14px !important;
}

.margin-horizontal-24 {
  margin-left: 24px;
  margin-right: 24px;
}
.pdf-not-work {
  padding: 140px 20px 0;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 25px;
  background: #23ad2f;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: transparent;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #04aa6d;
  cursor: pointer;
}

// Liquidity
// Liquidity
// Liquidity

.liquidation-wrapper .claim-all-button {
  width: auto;
  font-size: 14px;
  align-self: flex-start;
  margin-left: 0;
  margin-bottom: 24px;
  display: block;
}

.liquidity-wrap {
  display: flex;
  justify-content: space-between;
}

.liquidity-side {
  text-align: left;
  width: 670px;
}

.liquidity-side.liquidity-side-right {
  width: 440px;
}

.liquidity-wrap .info-board {
  padding: 20px;
  border-radius: 14px;
  margin: 12px 0;
  background: #10251f;
}

.liquidity-wrap .info-detail {
  display: flex;
  justify-content: space-between;
  padding: 24px 0 0;
}

.liquidity-wrap .info-detail .info-value-right-section {
  width: 100px;
}

.liquidity-wrap .liquidity-title {
  font-size: 32px;
  font-weight: bold;
}
.liquidity-wrap .liquidity-desc {
  color: var(--base-gray);
  font-size: 16px;
}

.liquidity-wrap .info-value-top {
  padding-bottom: 24px;
}

.liquidity-wrap .info-value-title {
  font-size: 12px;
  color: var(--base-gray);
}
.liquidity-wrap .info-value-amount {
  font-size: 14px;
  font-weight: bold;
}

.liquidity-wrap .info-value-amount.dollar {
  color: var(--base-gray);
  font-weight: 100;
  font-size: 14px;
}

.liquidity-wrap .token-board {
  margin: 12px 0;
  background-color: #112d25;
  border-radius: 14px;
  overflow: hidden;
}

.liquidity-wrap .liquidity-token-list {
}
.liquidity-wrap .liquidity-token-list .liquidity-token {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 14px;
  padding: 14px 0;
}

.liquidity-wrap .liquidity-token-width {
  width: 130px;
  margin: unset;
  font-size: 14px;
}

.liquidity-wrap .liquidity-token__button {
  text-align: center;
}

.liquidity-wrap .liquidity-token__button button {
  width: 100px;
  font-size: 12px;
}
.liquidity-wrap .liquidity-token__symbol {
  display: flex;
  align-items: center;
  justify-content: center;
}
.liquidity-wrap .liquidity-token__symbol .coin-des {
  padding-left: 8px;
  width: 64px;
}
.liquidity-wrap .liquidity-token__price {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.liquidity-wrap .liquidity-token__price .amount {
  font-size: 16px;
}
.liquidity-wrap .liquidity-token__price .dollar {
  color: var(--base-gray);
  font-size: 14px;
}
.liquidity-wrap .liquidity-token-cap {
  display: flex;
  justify-content: space-around;
  background-color: #041814;
  padding: 10px 0;
}
.liquidity-wrap .liquidity-token-cap h3 {
  width: 130px;
  text-align: center;
  font-size: 14px;
}

.info-slider .ant-slider-handle {
  background: transparent;
  border: transparent;
}

.info-slider .ant-slider-track {
  background-color: #23ad2f !important;
}

.info-slider .ant-slider-dot {
  background: var(--base-gray);
  border: none;
  width: 14px;
  height: 14px;
  top: -5px;
}

.info-slider .ant-slider:hover .ant-slider-handle,
.info-slider
  .wrap-popup
  .popup
  .slider-range
  .ant-slider-handle.ant-tooltip-open {
  background: transparent;
  border-color: transparent;
  width: auto !important;
}

.info-slider .ant-slider-track,
.info-slider .ant-slider-rail {
  height: 14px;
}
.info-slider .ant-slider-disabled .ant-slider-rail {
  background-color: #40514d !important;
}
.info-slider .ant-slider-disabled {
  cursor: pointer;
  height: 14px;
  border-radius: 6px;
  overflow: hidden;
  margin: auto;
  padding: 0;
}
.info-slider .ant-slider-track {
  border-radius: 6px;
}

// Liquidity
// Liquidity
// Liquidity

.common-button {
  width: 100%;
  text-align: center;
  padding: 8px 14px;
  outline: none;
  border-radius: 4px;
  margin: auto;
  font-size: 18px;
  font-weight: bold;
  background-color: var(--green-dark);
  border: 1px solid var(--greenlight);
  color: var(--white);
  opacity: 1;
  transition: 0.4s all;
}
.common-button:hover {
  cursor: pointer;
  background-color: var(--white);
  color: var(--black);
}

.common-button.yellow {
  background: rgba(255, 209, 102, 0.2);
  border: 1px solid rgba(255, 209, 102);
}
.common-button.yellow:hover {
  background: rgba(255, 209, 102, 0.12);
}

.common-button:active {
  opacity: 0.7;
}

.common-button.disable {
  background-color: #353945;
  border: 1px solid #353945;
  color: var(--base-gray);
  cursor: not-allowed;
}

// Loading custom

.loading-spin-wrapper {
  bottom: 0;
  margin: "auto";
  display: "flex";
  justify-content: "center";
  align-items: "center";
}
.ant-spin-blur {
  opacity: 1;
}
.ant-spin-container::after {
  background: #000;
}

.ant-spin-blur::after {
  opacity: 0.4;
}

.loading-spin-wrapper .ant-spin-spinning {
  top: 35% !important;
  transform: translateY(-50%);
}

.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-dot {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

p.loading-text {
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  padding-top: 12px;
}

img.loading-spin-icon {
  width: 64px;
  height: 64px;
  animation: rotation 1s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
// end Loading custom
// end Loading custom

// Ant Notification
// Ant Notification
.ant-notification {
  z-index: 99999;
}

.ant-notification-notice {
  background: rgb(0 0 0 / 73%);
  color: #fff;
  border-radius: 10px;
  border: 1px solid var(--black-transparent);
}
.ant-notification-notice-with-icon .ant-notification-notice-message {
  color: #fff;
  font-weight: bold;
}
// end Ant Notification
// end Ant Notification

// Auction Button
.auction-btn-wrap {
  position: absolute;
  top: 50%;
  right: -200px;
  transition: 0.4s all;
}

.auction-btn-wrap .link-auction {
  cursor: pointer;

  display: block;
}
.auction-btn-wrap:hover {
  transform: translateX(10px);
}

.auction-btn-wrap button {
  background: transparent;
  border: none;
  position: absolute;
  top: 10px;
  right: 10px;
  transform: scale(0.7);
  z-index: 88;
  cursor: pointer;
  transition: 0.4s all;
}
.auction-btn-wrap button:hover {
  opacity: 0.5;
}
.mobile-only-responsive {
  display: none;
}
// End Auction Button

.footer-wrapper-composition {
  justify-content: space-between;
  align-items: stretch;
  font-size: 14px;
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
}
@media screen and (max-width: 456px) {
  .mobile-responsive {
    justify-content: center;
    text-align: center;
  }
  .ant-card {
    max-width: 100% !important;
  }
}
@media screen and (max-width: 768px) {
  .brand-name {
    display: none !important;
  }
  .mobile-only-responsive {
    display: block;
  }
  .logo-mobile-responsive {
    display: flex;
    gap: 25px;
    align-items: center;
    border-bottom: 0.75px solid rgba(255, 255, 255, 0.3);
    padding-bottom: 20px;
    img {
      max-width: 100%;
      max-height: 100%;
    }
    .brand-name {
      display: flex !important;
    }
    .logo-wrapper-ico-child {
      width: 45px;
    }
  }
  .header {
    padding: 0 !important;
  }
  .homepage {
    padding: 150px 15px 0 !important;
  }
  .mobile-responsive-logo {
    width: 50px;
  }
  .mobile-responsive-logo {
    img {
      width: 100%;
      height: 100%;
    }
  }
  .section-market {
    padding: 0 !important;
  }
  .wrap-total {
    margin: 0 !important;
  }
  .wrapper-logo-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    height: 100%;
    padding: 0 15px;
    width: 100%;
  }
  .container.container-header {
    padding: 0 !important;
  }

  .mobile-responsive-navbar {
    background: #001612;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin-top: 0 !important;
    width: 100%;
    padding: 15px 24px;
    left: -820px;
    transition: all 0.5s ease;
    flex-direction: column;
    display: flex !important;
    .nav-menu {
      justify-content: flex-start;
      align-items: flex-start;
      flex: 1;
      .menus {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        .child-menu {
          font-size: 18px;
          margin-bottom: 24px;
          display: inline-block;
          padding: unset;
        }
        .light {
          display: none;
        }
      }
      .homepage-social {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
      }
      .without-homepage {
        padding-top: 24px;
      }
    }
  }
  nav.active.mobile-responsive-navbar {
    left: 0;
    transition: all 0.5s ease;
  }
  .vaults-comp {
    padding: 150px 15px 0 !important;
  }

  // #wrap-header-menu .hamburger {
  //   position: relative;
  // }
  // .header .container-header {
  //   padding: 0;
  // }
  // .mobile-responsive-logo {
  //   position: relative;
  //   top: 10px;
  // }
  // #wrap-header-menu .hamburger {
  //   top: 15px !important;
  // }
  .mobile-responsive-hero-banner {
    width: 100%;
    padding: 0 15px;
    .mobile-responsive {
      text-align: left;
      flex-wrap: nowrap;
      a {
        margin-left: 0 !important;
      }
    }
  }
  // .homepage.market-app {
  //   padding: 30px 15px 65px 15px;
  // }
  // #wrap-header-menu {
  //   bottom: 0;
  // }

  .menu-btn.button-style.menus {
    flex-direction: row;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 90px;
    background-color: rgb(0, 192, 118);
    border: none;
    color: rgb(255, 255, 255);
    display: flex;
    .btn-connect {
      padding: 10px 35px;
      text-overflow: ellipsis;
      max-width: 250px;
      width: 100%;
      overflow: hidden;
      .btn-label {
        color: white;
        font-size: 14px;
      }
    }
  }
  .mobile-solution-only,
  .mobile-tokenomic-only {
    padding: 0 15px;
  }
  .mobile-tokenomic-only {
    svg {
      width: 95vw;
    }
    .box {
      width: 100%;
      padding: 10px !important;
      .label {
        font-size: 14px !important;
      }
    }
  }
  .mobile-invester-only {
    margin-top: 0 !important;
    .mobile-invester-title-only {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .mobile-footer-only {
    padding: 40px 15px !important;
  }
  .footer-wrapper-composition {
    flex-direction: column;
    align-items: center;
    gap: 25px;
    .mobile-flex-only {
      align-items: center !important;
    }
    .about-us-mobile-only {
      gap: 10px !important;
    }
  }
  .mobile-investor-list-only {
    max-width: 165px !important;
    padding: 10px !important;
  }
  .mobile-responsive-banner-top {
    flex-direction: column-reverse !important;
  }
  .mobile-solution-only-wrapper {
    margin-top: 415px;
  }
  .mobile-right-banner-only {
    max-width: 355px !important;
  }
  .mobile-pie-chart-only {
    margin-top: 0 !important;
  }
}

.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}

.moveation-top {
  position: relative;
  -webkit-animation: moveationTop 5s ease-in infinite alternate;
  -o-animation: moveationTop 5s ease-in infinite alternate;
  animation: moveationTop 5s ease-in infinite alternate;
}

.moveation {
  position: relative;
  -webkit-animation: moveation 5s ease-in infinite alternate;
  -o-animation: moveation 5s ease-in infinite alternate;
  animation: moveation 5s ease-in infinite alternate;
}

.clock-animated-transform-scale {
  position: relative;
  animation: pulse-clock 3s infinite;
  border-radius: 50%;
}

.moveation-none-rotate-floop {
  position: relative;
  -webkit-animation: moveationNoneRotateLoop 7s ease-in infinite alternate;
  -o-animation: moveationNoneRotateLoop 7s ease-in infinite alternate;
  animation: moveationNoneRotateLoop 7s ease-in infinite alternate;
}

.moveation-none-rotate {
  position: relative;
  -webkit-animation: moveationNoneRotate 5s ease-in infinite alternate;
  -o-animation: moveationNoneRotate 5s ease-in infinite alternate;
  animation: moveationNoneRotate 5s ease-in infinite alternate;
}

@keyframes moveationNoneRotate {
  0% {
    bottom: 0;
  }
  50% {
    bottom: 20px;
  }
  100% {
    bottom: 0;
  }
}

@keyframes moveationNoneRotateLoop {
  0% {
    transform: rotateY(0);
  }
  100% {
    transform: rotateY(360deg);
  }
}

@keyframes pulse-clock {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.05);
  }

  70% {
    transform: scale(1.2);
    box-shadow: 0 0 0 50px rgba(255, 255, 255, 0);
  }

  100% {
    transform: scale(0.95);
  }
}

@-webkit-keyframes pulse-clock {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.05);
  }

  70% {
    transform: scale(1.2);
    box-shadow: 0 0 0 50px rgba(255, 255, 255, 0);
  }

  100% {
    transform: scale(0.95);
  }
}
@-moz-keyframes pulse-clock {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.05);
  }

  70% {
    transform: scale(1.2);
    box-shadow: 0 0 0 50px rgba(255, 255, 255, 0);
  }

  100% {
    transform: scale(0.95);
  }
}
@-o-keyframes pulse-clock {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.05);
  }

  70% {
    transform: scale(1.2);
    box-shadow: 0 0 0 50px rgba(255, 255, 255, 0);
  }

  100% {
    transform: scale(0.95);
  }
}

@keyframes moveationTop {
  0% {
    bottom: 0;
    transform: rotateZ(0deg);
  }
  50% {
    bottom: 50px;
    transform: rotateZ(90deg);
  }
  100% {
    bottom: 0;
    transform: rotateZ(0deg);
  }
}

@keyframes moveation {
  0% {
    left: 0;
    transform: rotateZ(0deg);
  }
  50% {
    left: 20px;
    transform: rotateZ(50deg);
  }
  100% {
    left: 0;
    transform: rotateZ(0deg);
  }
}
