.nav-menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .menus {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .light {
      background-color: var(--greenlight);
      width: 4px;
      height: 4px;
    }
    .menu {
      text-transform: capitalize;
      position: relative;
      padding: 30px;
      .text {
        font-weight: 700;
        transition: all 0.3s ease;
      }
    }
  }
}

.ver-2 {
  .btn-connect {
    padding: 25px !important;
    border-left: 1px solid #1f2931;
  }
  .menu {
    display: flex;
  }
  .text {
    margin-right: 10px;
  }
}
.menu-btn {
  height: 100%;
  .btn {
    color: var(--greenlight);
    span {
      font-weight: 700;
    }
  }
  &:hover {
    .text {
      color: var(--greenlight);
    }
    .more {
      display: block;
    }
  }
  .more {
    display: none;
    position: absolute;
    top: 72px;
    text-align: center;
    border-radius: 4px;
    z-index: 9;
    min-width: 155px;
    left: 0;
    .wrap-toggle {
      border: 1px solid #49e454;
      background: linear-gradient(
          180deg,
          rgba(84, 169, 67, 0.08) 50%,
          rgba(84, 169, 67, 0.2) 100%
        ),
        linear-gradient(0deg, rgba(4, 21, 8, 0.5), rgba(4, 21, 8, 0.5)),
        linear-gradient(
          180deg,
          rgba(4, 36, 31, 0.8) 0%,
          rgba(4, 36, 31, 0) 100%
        );
      background-color: #000;
      border-radius: 6px;
      padding: 18px;
      a,
      span {
        padding: 8px 0;
        text-align: left;
        border-bottom: 1px solid var(--sub-greenlight);
        text-transform: capitalize;
        display: block;
        white-space: nowrap;
        color: var(--white);
        font-weight: 600;
        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
        }
      }
      span {
        color: var(--white);
        font-weight: 700;
      }
    }
  }
  .btn-header {
    width: 130px;
    .wrap-toggle {
      span {
        white-space: nowrap;
      }
    }
  }
  .btn-label {
    font-weight: 700;
    color: var(--greenlight);
    font-size: 16px;
  }
}
.non-login {
  opacity: 0.5;
  &:hover {
    opacity: 0.5;
  }
}
.border-right {
  border-right: 1px solid #1f2931;
}

.logged-menu {
  &:hover {
    a {
      color: var(--greenlight);
    }
  }
}

.btn-connect {
  cursor: pointer;
  position: relative;
}
.right-wallet-btn {
  height: 100%;
  .btn-connect {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .btn-label {
      background: linear-gradient(
          180deg,
          rgba(84, 169, 67, 0) 0%,
          rgba(84, 169, 67, 0.2) 100%
        ),
        rgba(84, 169, 67, 0.1);
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 15px;
      border: 1px solid var(--greenlight);
      border-radius: 6px;
      color: var(--white);
    }
    .active {
      color: white;
      font-weight: 600;
      display: block;
    }
  }
}
.mobile-sub-item {
  padding-top: 24px;
}
