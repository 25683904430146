$hand-font: "SVN-RosellindaAlyamore";

@font-face {
  font-family: $hand-font;
  font-style: normal;
  font-weight: 400;
  src: local(""), url("/fonts/SVN-RosellindaAlyamore.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("/fonts/SVN-RosellindaAlyamore.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

.App {
  overflow: auto;
}
.container-all {
  height: 100%;
  overflow: auto;
}
.lang-biang-club {
  .nav-menu .menus {
    width: 100%;
  }
  .title-social {
    font-weight: 700;
    color: var(--white);
  }
  .mobile-only {
    display: none;
  }
  .desktop-only {
    display: block;
  }
  height: 100%;
  #wrap-header-menu {
    background: transparent;
    border: none;
    position: absolute;
    .container {
      position: absolute;
      right: 0;
    }
  }
  .top-container {
    height: 100%;
    .cover-bg {
      height: 100%;
      width: 100%;
      background-color: #001612;
      img {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        object-fit: cover;
        height: 100%;
      }
    }
    .banner-content {
      position: absolute;
      inset: 0;
    }
    .welcome {
      text-align: center;
      color: #ffffff;
      position: relative;
      top: 100px;
      h4 {
        font-weight: 600;
        opacity: 0.6;
        font-size: 24px;
        font-family: "Poppins", sans-serif;
      }
      h2 {
        font-family: $hand-font !important;
        font-size: 8rem;
        text-transform: capitalize;
        line-height: 11rem;
      }
      p {
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        color: #ffffff;
        opacity: 0.6;
        margin-bottom: 24px;
      }
      .btn-discord-now {
        font-weight: 600;
        font-size: 18px;
        color: inherit;
        transition: all 0.3s ease;
        &:hover {
          border: 2px solid white;
          padding: 10px 15px;
          border-radius: 8px;
          font-weight: 800;
        }
      }
    }
  }
  .benefits-box {
    padding: 85px 0 375px 0;
    position: relative;
    background: rgb(97, 168, 17);
    background: linear-gradient(180deg, #329a33 0%, rgba(50, 154, 51, 0) 100%);
    .benefits {
      color: #ffffff;
      margin: auto;
      .title {
        text-align: center;
        font-weight: 500;
        font-size: 50px;
        display: inherit;
        .lang-biang {
          font-family: $hand-font !important;
        }
      }
      div.lst-benefits-img {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: start;
        align-content: center;
        justify-content: space-between;

        .benefit {
          padding-top: 30px;
          max-width: 345px;
          width: 100%;
          border-radius: 12px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          transition: all 0.3s ease;

          &:hover {
            img {
              transition: all 0.3s ease;
              transform: translateX(10px);
            }
          }

          span {
            p.idx {
              font-weight: 600;
              opacity: 0.2;
              font-size: 32px;
            }

            p.desc {
              font-weight: normal;
              font-size: 17px;
              opacity: 0.8;
            }

            img {
              width: 100%;
              height: 200px;
              transition: all 0.3s ease;
            }
          }
        }
      }
    }
  }
  .join {
    position: relative;
    background-size: cover;
    text-align: center;
    color: #ffffff;
    background: linear-gradient(
        180deg,
        rgba(220, 235, 233, 0) 79.38%,
        #dcebe9 100%
      ),
      linear-gradient(180deg, #ffeff3 0%, rgba(255, 255, 255, 0) 29.23%),
      linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.4) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      url("../images/backgroundboat.png");

    &::after,
    &::before {
      content: "";
      position: absolute;
      max-width: 500px;
      width: 100%;
      height: 160px;
      filter: blur(3px);
      top: -135px;
    }
    &::after {
      background: url("../images/clound2.png") no-repeat;
      left: 0;
    }
    &::before {
      background: url("../images/clound2.png") no-repeat;
      right: 0;
      transform: scaleX(-1);
    }
    .title {
      color: #c9050d;
      font-weight: 500;
      font-size: 45px;
      .how-join {
        line-height: 40px;
        font-weight: 600;
        margin-right: 15px;
      }
      .lang-biang {
        font-family: $hand-font !important;
        font-size: 50px;
      }
    }
    .guides-box {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: start;
      align-content: center;
      width: 100%;
      justify-content: space-between;
      padding: 30px 50px;
      .guide {
        text-align: left;
        max-width: 355px;
        width: 100%;
        transition: all 0.3s ease;
        &:hover {
          transform: translateY(-10px);
          .guide-wrapper {
            img {
              transform: scale(0.8) rotate(360deg);
              transition-duration: 0.7s;
            }
          }
        }
        .guide-wrapper {
          background: rgba(255, 255, 255, 0.4);
          backdrop-filter: blur(20px);
          border-radius: 20px;
          padding: 7px;
        }
        .guide-des {
          padding: 75px;
        }
        div:first-child {
          display: flex;
          justify-content: center;
          justify-items: center;
          align-items: center;
          img {
            width: 200px;
            height: 200px;
            transition: all 0.3s ease;
            border-radius: 20px;
          }
          div {
            padding: 25px;
            p.idx {
              font-weight: 600;
              font-size: 25px;
              color: #c9050d;
              line-height: 130%;
            }
            p.desc {
              font-weight: 575;
              color: #031220;
              font-size: 14px;
              line-height: 130%;
              margin-top: 8px;
              white-space: pre-wrap;
            }
          }
        }
      }
    }
    .boat {
      width: 100%;
      height: 100%;
      overflow: hidden;
      padding: 125px 75px 75px 75px;
      img {
        width: 100%;
        height: 100%;
        transform: scale(1.2);
      }
    }
    .joining-wrapper {
      padding-top: 350px;
    }
    .join-ntfcollection {
      position: absolute;
      width: 100%;
      top: -18%;
      z-index: 100;
    }
  }
  .nfts-box {
    .nft-box-container {
      position: relative;
      &::before {
        content: "";
        background: url("../images/langbiang/leaf.png") no-repeat;
        width: 100%;
        height: 200px;
        left: 0;
        position: absolute;
        max-width: 300px;
        background-size: contain;
        z-index: 2;
        bottom: -120px;
      }
    }
    .nfts {
      display: flex;
      background-color: #ffffff;
      border-radius: 16px;
      margin: 0 auto;
      position: relative;
      overflow: hidden;
      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: url("../images/langbiang/grid.png") no-repeat;
      }
      .desc-box {
        width: calc(100% - 600px);
        justify-items: center;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: auto;
        z-index: 2;
        line-height: 130%;
        padding-left: 100px;
        position: relative;
        &::after {
          content: "";
          background: url("../images/langbiang/leaf.png") no-repeat;
          width: 100%;
          height: 200px;
          right: -25%;
          position: absolute;
          top: -50%;
          max-width: 300px;
          background-size: contain;
        }
        h3 {
          color: #c9050d;
          font-size: 70px;
          font-family: $hand-font !important;
          line-height: 130%;
        }
        .desc {
          color: #1f2931;
          font-weight: 600;
          font-size: 75px;
          line-height: 130%;
        }
        .inspired {
          font-weight: 600;
          color: #1f2931;
          font-size: 20px;
          line-height: 130%;
        }
        a.paras {
          margin-top: 25px;
          width: 177px;
          height: 51px;
          background: linear-gradient(
              90deg,
              rgba(255, 255, 255, 0.2) 0%,
              rgba(255, 255, 255, 0) 100%
            ),
            #23ad2f;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #ffffff;
          font-weight: 700;
          font-size: 16px;
          transition: all 0.3s ease;
          &:hover {
            border: 2px solid #23ad2f;
            color: #23ad2f;
            background: transparent;
          }
        }
      }

      .nfts-box-list {
        max-width: 600px;
        display: flex;
        border-radius: 8px;
        justify-content: flex-start;
        flex-flow: wrap;
        z-index: 2;
        position: relative;
        .box {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 120px;
          width: 120px;

          img {
            width: 100%;
            height: 100%;
          }

          &:nth-child(1) img {
            border-top-left-radius: 8px;
          }

          &:nth-child(5) img {
            border-top-right-radius: 8px;
          }

          &:nth-child(16) img {
            border-bottom-left-radius: 8px;
          }

          &:last-child img {
            border-bottom-right-radius: 8px;
          }
        }
      }
    }
  }
  .footer {
    width: 100%;
    background-color: #e0ebea;
    height: 75px;
    .container-wrapper {
      border-top: 1px solid rgba(255, 255, 255, 0.4);
      display: flex;
      align-items: center;
      height: 100%;
    }
    .icons-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      a {
        font-weight: 600;
        font-size: 14px;
        color: #1f2931;
        opacity: 0.6;
        min-width: 100px;
        &:not(:first-child) {
          padding-left: 20px;
        }
        line-height: 150%;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  header#wrap-header-menu {
    // position: fixed !important;
    // overflow: auto;
    // background: rebeccapurple !important;
    .container {
      nav {
        display: block !important;
      }
    }
  }

  .lst-benefits-img {
    justify-content: center !important;
  }
  .nfts-box {
    .nfts {
      flex-wrap: wrap;
    }
  }
  .lang-biang-club .nfts-box .nfts .desc-box {
    width: 100%;
    padding-left: 0;
    padding: 90px 15px;
    align-items: center;
  }
  .lang-biang-club .nfts-box .nfts .desc-box .desc {
    font-size: 36px;
  }
  .lang-biang-club .nfts-box .nfts .desc-box .inspired {
    font-size: 18px;
  }
  .lang-biang-club .nfts-box .nfts .nfts-box-list {
    justify-content: center;
  }
  .lang-biang-club .nfts-box .nfts .nfts-box-list .box:nth-child(5) img {
    border-top-right-radius: unset;
  }
  .lang-biang-club .nfts-box .nfts .nfts-box-list .box:nth-child(3) img {
    border-top-right-radius: 8px;
  }
  .lang-biang-club .nfts-box .nfts .nfts-box-list .box:nth-last-child(2) img {
    border-bottom-left-radius: 8px;
  }
  .lang-biang-club .nfts-box .nfts .nfts-box-list .box:nth-last-child(3) img {
    border-bottom-right-radius: 8px;
  }
  .lang-biang-club .join .join-ntfcollection {
    top: 0;
    position: relative;
  }
  .lang-biang-club .join::after,
  .lang-biang-club .join::before {
    display: none;
  }
  .lang-biang-club .benefits-box {
    margin-top: -1px;
    padding: 50px 0 100px 0;
  }
  .lang-biang-club .join .joining-wrapper {
    padding-top: 100px;
  }
  .lang-biang-club .join .guides-box {
    padding: 15px;
    a {
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }
  .lang-biang-club {
    #wrap-header-menu nav {
      min-height: unset !important;
    }
    #wrap-header-menu nav {
      margin-top: 0;
    }
    .nav-menu {
      justify-content: space-between;
    }
    .mobile-logo-langbiang {
      display: flex !important;
      align-items: center;
      gap: 15px;
      span {
        font-weight: 900;
        font-size: 17px;
        white-space: nowrap;
      }
    }
  }
  .lang-biang-club .top-container .cover-bg {
    height: 150vh;
  }
  .lang-biang-club .benefits-box .benefits .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 65px;
    font-size: 24px;
  }
  .lang-biang-club .benefits-box .benefits .title .lang-biang {
    font-size: 6rem;
    display: block;
  }
  .lang-biang-club .benefits-box .benefits .title .text {
    margin-top: 20px;
    display: block;
  }
  .mobile-only {
    display: block !important;
  }
  .desktop-only {
    display: none !important;
  }
  .lang-biang-club .nav-menu .menus a {
    &:nth-child(1) {
      flex: 1;
    }
  }
  .langbiang-mobile-footer {
    display: flex;
    svg {
      path {
        fill: #777e91;
      }
    }
  }
  .lang-biang-club .footer .icons-wrapper a {
    width: 100%;
    min-width: unset;
  }
  .lang-biang-club .footer {
    padding: 0 15px;
  }
}
