.title-box {
  .title {
    color: var(--greenlightSecondary);
    font-weight: 700;
    font-size: 22px;
    margin-bottom: 6px;
  }
  .des {
    font-weight: 500;
    font-size: 16px;
    color: whitesmoke;
  }
}

.body-vault-modal {
  max-height: calc(100vh - 425px);
  overflow: auto;
  .body-vault-modal-na {
    text-align: center;
  }
  .flex-style {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .label-top {
    padding: 0 10px;
    margin-bottom: 15px;
    .body-label {
      text-transform: capitalize;
      font-weight: 500;
      font-size: 16px;
    }
  }
  .list-content {
    overflow-x: scroll;
    max-height: 400px;
    height: 400px;
    .item-tag {
      background: rgba(255, 255, 255, 0.05);
      margin-bottom: 8px;
      padding: 14px 10px;
      border-radius: 8px;
    }
  }
  .list-content::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .list-content::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background-color: transparent;
  }

  .list-content::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  .vaults-btn {
    cursor: pointer;
    text-transform: capitalize;
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    /* BG Green */
    background: linear-gradient(
        180deg,
        rgba(84, 169, 67, 0) 0%,
        rgba(84, 169, 67, 0.2) 100%
      ),
      rgba(84, 169, 67, 0.1);
    border-radius: 6px;
    border: 1px solid var(--greenlight);
    font-weight: 700;
    font-size: 13px;
  }
}
