.vaults-comp {
  flex: 1;
  padding: 150px 24px 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  .ant-table-wrapper,
  .ant-tabs-content-top {
    border-radius: 6px;
    overflow: hidden;
  }
  .ant-table-wrapper {
    margin-bottom: -2px;
  }
  .ant-table {
    background: rgba(255, 255, 255, 0.05);
    color: white;
    td.ant-table-cell {
      background-color: transparent;
      transition: all 0.3s ease;
      min-width: 200px;
    }
    thead {
      background: #041814;
    }
  }

  .ant-table-content table {
    min-height: 79px;
  }
  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: unset;
    cursor: pointer;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  }
  .ant-table-thead > tr > th {
    background: transparent;
    border-bottom: none;
    color: white;
    padding: 28px 16px;
    font-size: 15px;
    font-weight: 700;
  }
  .claim-nft {
    cursor: pointer;
    padding-left: 20px;
    padding-bottom: 4px;
    color: var(--yellow);
    text-decoration: underline;
    a {
      color: inherit;
    }
  }
  .wrapper-top-box {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 24px;
    .top-box {
      padding: 10px 20px;
      text-align: center;
      text-transform: capitalize;
      color: white;
      border-radius: 6px;
      font-weight: 700;
      cursor: pointer;
      &:first-child {
        padding: 20px 30px;
        display: flex;
        align-items: center;
        background: linear-gradient(
            180deg,
            rgba(222, 53, 11, 0) 0%,
            rgba(222, 53, 11, 0.8) 100%
          ),
          rgba(222, 53, 11, 0.3);
        border: 1px solid #de350b;
        transition: 0.4s all;
        &:hover {
          opacity: 0.7;
        }
      }
      &:nth-child(2),
      &:nth-child(3) {
        flex: 1;
        background: rgba(255, 255, 255, 0.05);
        backdrop-filter: blur(14px);
        padding: 20px;
      }
      .box-title {
        color: whitesmoke;
        font-size: 16px;
        font-weight: 600;
      }
      .box-content {
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: center;
      }
      .main-value {
        font-weight: 700;
        font-size: 28px;
        text-overflow: unset;
        overflow: inherit;
      }
    }
  }

  .main-value {
    font-size: 15px;
    font-weight: 600;
    text-overflow: ellipsis;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
  }
  .flex-reward {
    display: flex;
    align-items: center;
  }
  .ant-tabs-nav {
    margin-bottom: 0;
    color: whitesmoke;
    .ant-tabs-tab-btn {
      font-weight: 700;
      font-size: 16px;
    }

    .ant-tabs-tab {
      &:hover {
        color: var(--greenlight);
      }
    }
  }
  .tab-content {
    background-color: rgba(255, 255, 255, 0.05);
    color: whitesmoke;
    padding: 20px 30px;
  }
  .ant-tabs-ink-bar {
    background: var(--greenlight);
    height: 1px !important;
  }
  .head-item {
    color: white;
    font-size: 16px;
    position: relative;
    &:not(:first-child) {
      &::before {
        height: 100%;
        width: 1px;
        background-color: var(--greenlight);
        opacity: 0.3;
        content: "";
        position: absolute;
        top: 0;
        left: -25px;
      }
    }
    .label {
      color: var(--white);
      margin-bottom: 8px;
    }
    .value {
      .main-value {
        font-weight: 600;
        font-size: 24px;
      }
      .sub-value {
        margin-left: 10px;
        font-size: 14px;
        color: var(--gray-neutrals);
      }
    }
  }

  .ant-empty-description {
    color: var(--white);
  }
}
.hightlight-label {
  color: #7fffd4;
  font-weight: 600;
}
.sub-value {
  color: var(--gray-neutrals);
}

.ant-tabs-tab-active {
  padding: 13px;
  background: linear-gradient(
      180deg,
      rgba(84, 169, 67, 0) 0%,
      rgba(84, 169, 67, 0.8) 100%
    ),
    rgba(84, 169, 67, 0.4);

  box-shadow: 0px 20px 30px rgba(84, 169, 67, 0.2);
  .ant-tabs-tab-btn {
    color: var(--white);
  }
}

.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  display: none;
}
.hight-2-light-tokenID {
  color: var(--greenlight-pastel);
}
.font-weight-600 {
  font-weight: 600;
}
