@media only screen and (max-width: 1042px) {
  html {
    overflow-x: hidden;
  }
  .market-app.homepage .wrap-total {
    margin: 0 24px;
  }
  .container.portfolio {
    padding: 150px 24px 0;
  }
  .header {
    padding: 0 20px;
  }
}
@media only screen and (max-width: 1024px) {
  .introduce-page .section-2 .flex,
  .introduce-page .section-2 .flex-side {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 0;
  }

  .introduce-page .section-2 .flex-side .flex-side__side {
    padding-bottom: 100px;
  }

  .introduce-page .section-2.section-top .content {
    margin: 0;
    text-align: center;
    max-width: unset;
  }

  .vaults-comp .ant-tabs-content-holder {
    overflow: scroll;
  }
  .vaults-comp .ant-tabs-content-top {
    width: 1200px;
  }
  .vaults-comp .ant-table td.ant-table-cell {
    min-width: 220px !important;
  }
}
@media screen and (max-width: 920px) {
  /* .homepage .wrap-market .title {
    position: absolute;
  } */

  #wrap-header-menu {
    background-color: var(--origin-black);
  }

  #wrap-header-menu .hamburger {
    font-size: 30px;
    /* position: fixed;
    top: 20px;
    right: 30px; */
  }

  #wrap-header-menu .container {
    width: 100%;
    margin: auto;
    text-align: center;
  }

  #wrap-header-menu nav.active {
    display: block;
  }

  #wrap-header-menu nav ul {
    display: flex;
    flex-direction: column;
  }
  #wrap-header-menu nav ul li {
    margin: 12px;
  }

  .homepage .wrap-market {
    max-height: 520px;
    margin-bottom: 100px;
  }

  .homepage .wrap-market .pools {
    max-height: 400px;
    overflow: auto;
    /* padding-bottom: 100px; */
  }

  .homepage .wrap-market .title {
    padding: 40px 20px 20px 24px;
  }

  .App {
    padding-bottom: 0;
    overflow-y: scroll;
  }

  .waveWrapperInner {
    width: 250%;
    bottom: -15px;
  }
  .wrap-popup .popup {
    top: 50%;
  }

  .wrap-popup .popup .wrap-white {
    padding: 17px 0;
    /* background-color: #fff; */
  }

  .Wave {
    width: auto;
  }
  .roadmap .section-bot .title,
  .introduce-page .section-2 .title,
  .introduce-page .social-media .title {
    font-size: 34px;
  }

  .introduce-page .section-2 .title {
    padding-bottom: 0;
  }

  /* .portfolio .token__logo {
    flex-direction: column;
  }
  .portfolio .label__token-mini .token_name {
    display: none;
  }

  .portfolio .token__logo img {
    margin: auto;
  }

  .portfolio .token__logo .img {
    margin: auto;
  } */

  .total-balance .wrap-total {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .total-balance .total {
    width: 100%;
    margin-bottom: 16px;
  }

  .portfolio .token-list .wrap-info,
  .portfolio .token-list .side .detail .label.label__title {
    min-width: 820px;
  }
  .portfolio .token-list .side .detail {
    overflow: scroll;
  }

  .section-market {
    overflow: scroll;
    padding: 0 24px;
  }

  .vaults-comp .wrapper-top-box {
    flex-direction: column;
  }
  .vaults-comp .wrapper-top-box .top-box:first-child {
    padding: 36px;
    justify-content: center;
  }
  .vaults-comp .wrapper-top-box .top-box .box-content {
    flex-direction: column;
    gap: 0;
  }
  .vaults-comp .ant-tabs-content-holder,
  .vaults-comp .ant-tabs-nav,
  .vaults-comp .wrapper-top-box {
    padding: 0 0;
  }
  .claim-nft {
    padding: 0 0 10px !important;
    text-align: center;
  }

  .liquidation-wrapper {
    padding: 150px 24px 0;
  }
  .liquidity-wrap {
    flex-direction: column;
  }
  .liquidity-side {
    margin-bottom: 44px;
  }
  .liquidity-side,
  .liquidity-side.liquidity-side-right {
    width: 100%;
  }

  .icons-bottom .test-net-button {
    bottom: auto;
    text-align: center;
    right: auto;
    left: auto;
    width: 100%;
    position: relative;
    padding-bottom: 24px;
  }
}

@media only screen and (max-width: 768px) {
  .mobile-menu-bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
  }
  #wrap-header-menu .container {
    width: 100%;
  }
  #wrap-header-menu nav {
    display: none;
    min-height: 100vh;
    margin-top: 24px;
  }
  .introduce-page .head {
    background: url("./images/globe-block.png") no-repeat center bottom / cover;
  }
  .introduce-page .head .logo {
    /* display: none; */
    max-width: 320px;
    max-height: 320px;
  }
  .introduce-page .head h2 {
    top: 50%;
    width: auto;
    font-size: 54px;
  }

  .introduce-page .section-2 {
    padding: 100px 20px;
  }
  .introduce-page .section-2 .flex,
  .introduce-page .section-2.section-3 .flex {
    flex-direction: column;
  }

  .introduce-page .section-2.section-3 .content,
  .introduce-page .section-2 .content {
    text-align: center;
  }
  .introduce-page .section-2 .last {
    padding-top: 40px;
  }

  .roadmap {
    padding: 40px 0;
  }

  .roadmap .ship-1 {
    position: relative;
    top: -10px;
    left: 30px;
  }
  .roadmap .ship-2 {
    position: relative;
    text-align: right;
    right: 20px;
    top: 0;
    margin-bottom: -70px;
  }

  .roadmap .section-top .text {
    text-align: left;
  }
  .roadmap .section-top .col .content {
    width: auto;
    margin: 40px 14px;
  }

  .roadmap .section-bot {
    padding: 40px 0 80px;
  }
  .roadmap .section-bot .title {
    /* text-align: left; */
    margin: 0 14px 44px;
  }

  .roadmap .wrap-line::before,
  .roadmap .wrap-line .wrap .planet {
    left: 10%;
    height: 100%;
  }

  .roadmap .wrap-line .phase,
  .roadmap .wrap-line .phase.left {
    width: auto;
    margin: 0 14px 0 23%;
  }

  .roadmap .wrap-line .wrap {
    padding-bottom: 60px;
  }
  .roadmap .wrap-line .phase.right h4 {
    right: auto;
  }

  .roadmap .wrap-line .phase.right {
    text-align: left;
  }

  .roadmap .wrap-line .phase h4 {
    margin-bottom: 14px;
    position: relative;
    left: auto;
    right: auto;
    width: auto;
    display: inline-block;
  }

  /* Popup */
  .wrap-popup .popup {
    /* min-width: 100%; */
    max-width: 400px;
    margin: 0;
  }
  .popup-open {
    overflow: hidden;
  }
  /* end Popup */
}

@media only screen and (max-width: 600px) {
  .introduce-page .section-2 .flex-side .img {
    height: 320px;
  }

  .portfolio .token-list {
    flex-direction: column;
  }

  .portfolio .token-list .side {
    width: 100%;
    margin-bottom: 45px;
  }
  .portfolio .token-list::before {
    content: none;
  }

  .market-app .wrap-total {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 230px;
  }
  .market-app .wrap-total .sub-wrapp {
    width: 100%;
    margin-bottom: 24px;
  }

  .market-app .section-market {
    overflow: scroll;
  }

  .total-balance .total .value {
    font-size: 20px;
  }
  .total-balance .total .title {
    font-size: 14px;
  }
}
@media only screen and (max-width: 475px) {
  /* Popup */
  .wrap-popup .popup {
    min-width: 100%;
    max-width: 400px;
    margin: 0;
  }
  .wrap-popup .popup .info p {
    width: 50%;
  }
  /* end Popup */

  .introduce-page .social-media .list-social {
    flex-direction: column;
    align-items: center;
  }
  .introduce-page .social-media .list-social .link {
    margin-bottom: 30px;
  }

  .homepage .wrap-total {
    display: block;
    margin: 0 20px;
    text-align: center;
  }

  .portfolio.homepage .wrap-total {
    margin: 0;
  }

  .homepage .wrap-total .total.deposit {
    /* border-bottom: 1px solid var(--white); */
  }
}
