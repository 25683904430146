$base-width: 130px;
$base-width-w-button: 150px;
$base-border-radius: 8px;
$base-border-radius: 8px;

.homepage {
  padding: 150px 24px 0;
}
.market-app {
  background: url("../../images/markets/market-bg.png") no-repeat center;
  background-size: cover;
  min-height: 100vh;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 451px) {
    height: auto;
  }
  .wrap-total {
    display: flex;
    justify-content: space-between;
    .sub-wrapp {
      border-radius: $base-border-radius;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 17px;
      background: rgba(255, 255, 255, 0.05);
      color: var(--white);
      font-weight: bold;
      width: 49%;
    }
    .total {
      padding: 16px 24px;
      text-align: center;
    }

    .value {
      font-weight: 700;
      font-size: 32px;
    }
    .title {
      font-size: 16px;
      color: var(--white);
      font-weight: bold;
    }
    // .deposit {
    //   position: relative;
    //   &::after {
    //     content: "";
    //     content: none;
    //     width: 1px;
    //     height: 50%;
    //     background-color: var(--greenlight);
    //     position: absolute;
    //     right: 0;
    //     top: 0;
    //     bottom: 0;
    //     margin: auto;
    //     opacity: 0.2;
    //   }
    // }
  }
  .wrap-market {
    color: #c1c1c1;
    border-radius: 5px;
    // overflow-x: scroll;
    // overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    .title {
      font-size: 26px;
      padding: 40px 20px 20px 0;
      font-weight: 700;
      text-transform: capitalize;
    }

    .wrap-label {
      min-height: 79px;
      font-weight: bold;
      display: grid;
      grid-auto-flow: column;
      grid-row-gap: 10px;
      justify-content: space-around;
      padding: 14px 0 14px;
      min-width: 1000px;
      width: 100%;
      background-color: #041814;
      border-top-left-radius: $base-border-radius;
      border-top-right-radius: $base-border-radius;
      font-size: 15px;
      p {
        // text-align: center;
        font-weight: 600;
        margin: auto 0;
        text-transform: capitalize;
        width: 130px;
      }
    }

    .wrap-pool {
      display: grid;
      grid-auto-flow: column;
      grid-row-gap: 10px;
      justify-content: space-around;
      padding: 16px 0px 16px 0;
      min-width: 800px;
      transition: 0.4s all;
      position: relative;
      border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    }

    .pools {
      min-width: 1000px;
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    .pools::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .pools {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      background-color: rgba(255, 255, 255, 0.05);
      border-bottom-left-radius: $base-border-radius;
      border-bottom-right-radius: $base-border-radius;
    }

    .wrap-pool {
      .coin-des {
        .top {
          font-size: 16px;
          font-weight: 600;
        }
        .color-space-gray {
          font-size: 14px;
        }
      }
      &:hover {
        background-color: rgb(255 255 255 / 3%);
      }
      .update-v2 {
        width: $base-width-w-button;
        text-align: center;
      }
      .btn-base {
        cursor: pointer;
        pointer-events: all;
        background: #15310d;
        border: 1px solid var(--greenlight);
        color: #fff;
        border-radius: 6px;
        transition: 0.4s all;
        font-size: 13px;
        padding: 10px 14px;
        &:hover {
          background: #fff;
          color: #15310d;
        }
      }
    }

    .wrap-pool .top {
      margin-bottom: 0;
    }
    .fwb {
      font-size: 16px;
    }

    .wrap-pool .mini {
      width: $base-width;
      text-align: center;
      position: relative;

      .board-reward {
        transition: 0.4s all;
        opacity: 0;
        display: none;
        position: absolute;
        top: -50px;
        left: 50px;
        background: rgb(5 27 22);
        color: #000;
        min-width: 300px;
        width: max-content;
        padding: 16px;
        z-index: 9;
        border-radius: 8px;
        overflow: hidden;
        transform: translateY(-50%);
        h5 {
          padding-bottom: 20px;
          font-weight: bold;
          font-size: 14px;
        }
        .wrap-reward {
          .reward {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding-bottom: 16px;
            .reward-name {
              display: flex;
              p {
                font-weight: bold;
              }
            }
            .reward-per-day {
              padding-left: 24px;
            }
            &:last-child {
              padding-bottom: 0;
            }
          }
        }
      }
      &:hover {
        cursor: pointer;
        .board-reward {
          opacity: 1;
          display: block;
        }
      }
    }
    .mini-custom {
      width: 0 !important;
    }

    .wrap-pool .apy {
      flex: 1 1;
      p {
        width: 74px;
        text-align: right;
        margin-right: 10px;
      }
    }

    .wrap-pool .apy .icon-apy {
      padding: 0 2px;
    }

    .wrap-pool .apy .icon-apy img {
      background: var(--greenlight);
      border-radius: 50%;
      border: 1px solid #1c887a;
    }

    .wrap-pool .action {
      cursor: pointer;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      transition: 0.4s all;
    }

    // .wrap-pool .action:hover button {
    //   background: #1acab3;
    //   color: #000;
    // }
    .button-claim {
      color: var(--blue-sky);
      background-color: transparent;
      border: none;
      text-decoration: underline;
      cursor: pointer;
    }
    .button-claim.button-claim-outside {
      position: absolute;
      top: 50%;
      left: -40px;
      transform: translate(-50%, -50%);
    }

    .wrap-pool .action button.button-basic-deposit {
      background: #0f3313;
      color: white;
    }

    .wrap-pool .action button.button-basic-borrow:hover,
    .wrap-pool .action button.button-basic-deposit:hover {
      background: var(--white);
      color: #0f3313;
    }

    .wrap-pool .action button.button-basic-borrow {
      background: rgba(255, 209, 102, 0.2);
      color: white;
      border: 1px solid #ffd166;
    }

    .wrap-pool .icon {
      margin-right: 12px;
      border: 1px solid var(--greenlight);
      background: var(--white);
      border-radius: 50%;
    }

    .wrap-pool .asset {
      position: relative;
      justify-content: flex-start;
      text-align: left;
      // padding-left: 24px;
    }

    .btn-claim {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .market-flex {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}
.icons-bottom {
  width: 100%;
  padding: 30px 0;
  position: relative;
  .icons-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .icon-item {
      &:not(:last-child) {
        margin-right: 50px;
      }
    }
  }
  .test-net-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    a {
      margin: 0 4px;
      &:hover {
        opacity: 0.7;
      }
    }
  }
}

.w-50 {
  width: 50px !important;
}
.w-100 {
  width: $base-width !important;
}
.w-150 {
  width: $base-width-w-button !important;
}
